<template>
  <div id="showHeader">
    <h1 class="d-sr-only">NFT Update</h1>
<!--    <h1 class=" text-center">NFT Update</h1>-->
    <v-img class="mx-auto" :src="require('../assets/nft-update-logo-inv.svg')" contain width="256" />
  </div>
</template>

<script>
export default {
  name: "ShowHeader"
}
</script>

<style scoped>
  #showHeader {
    background: linear-gradient(180deg, rgba(215, 90, 99, 0.5) 75%, rgba(255, 255, 255, 1));
    margin-top: -4rem;
    padding: 4rem 0;
    z-index: 98;
  }
</style>