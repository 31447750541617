import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import vueCardano from "@/plugins/vue-cardano";
import VuePluralize from 'vue-pluralize';
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

Vue.use(vueCardano);
Vue.use(VueSweetalert2);
Vue.use(VuePluralize);

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app')
