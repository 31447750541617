<template>
  <span class="countdown">
    <span v-if="days > 0"> {{ days }} days</span>
    <span v-if="hours > 0"> {{ hours }} hours</span>
    <span v-if="days > 0 || hours > 0 || minutes > 0"> {{ minutes }} minutes</span>
    <span v-if="days <= 0"> {{ seconds }} seconds</span>
  </span>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Countdown",
  props: {
    timestamp: {
      required: true,
      type: Date,
    }
  },
  data: () => ({
    hours: 0,
    minutes: 0,
    days: 0,
    seconds: 0,
    looper: false,
    counting: null,
    now: new Date
  }),
  emits: ['countdownComplete'],
  mounted() {
    this.looper = setInterval(() => {
      let now = new Date().getTime();
      if (this.timestamp.getTime === undefined) {
        return;
      }
      let distance = this.timestamp.getTime() - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.min(59, Math.ceil((distance % (1000 * 60)) / 1000));

      if (this.days < 0) {
        this.days = 0;
      }

      if (this.hours < 0) {
        this.hours = 0;
      }

      if (this.minutes < 0) {
        this.minutes = 0;
      }

      if (this.seconds < 0) {
        this.seconds = 0;
      }

      if (distance < 0) {
        this.$emit('countdownComplete');
        clearInterval(this.looper);
      }
    }, 1000);
  }
}
</script>

<style scoped>
  .countdown > span:after {
    content: ','
  }

  .countdown > span:last-child:after {
    content: '.'
  }
</style>