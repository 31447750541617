<template>
  <v-app>
    <v-app-bar app color="primary" dark elevation="0" shrink-on-scroll>
      <div class="d-flex flex-row flex-nowrap align-center" id="title">
        <v-img alt="Buffy Bot" class="shrink mr-2 hidden-sm-and-down" contain :src="require('./assets/buffy.svg')"
               transition="scale-transition" height="45" />
        NFT Update
      </div>

      <v-spacer></v-spacer>

      <v-btn href="https://www.youtube.com/channel/UCF-OblrdNwU1craUfgOG12A" target="_blank" text>
        <span class="mr-2">Watch Now</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <HeroBanner :latest="latestEpisode" @triggerBuy="doBuy"></HeroBanner>
      <ShowHeader></ShowHeader>
      <SeasonList :seasons="seasons" @triggerBuy="doBuy" @newRelease="updateLatest"></SeasonList>
    </v-main>
    <v-footer color="transparent">
      <v-container>
        <v-row>
          <v-col>
            <v-img :src="require('./assets/powered-by-peach.svg')" alt="Powered by Buffy Bot"
                   title="Powered by Buffy Bot" contain width="256" />
          </v-col>
          <v-col>
            <p class="text-end">
              Copyright &copy; 2023, Buffy Bot Publishing, LLC. All rights reserved. </p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog v-model="showBuy" persistent max-width="512">
      <v-card>
        <v-card-title color="primary">Get Your NFT Update Promo Card!</v-card-title>
        <template v-if="cardano.status === 'init'">
          <v-card-text class="body-1">
            Looking for Cardano light wallets...
          </v-card-text>
          <v-card-text>
            <v-progress-linear height="12" color="accent" indeterminate></v-progress-linear>
          </v-card-text>
        </template>
        <template v-if="cardano.status === 'found'">
          <v-card-text class="body-1">
            Please connect your wallet to get started...
          </v-card-text>
          <v-card-text>
            <v-list :disabled="connectingWallet">
              <v-list-item v-for="wallet in cardano.Wallets" :key="wallet.name" @click="connectTo(wallet)">
                <v-list-item-icon>
                  <v-img :src="wallet.icon" width="24" height="24" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="wallet.name" class="text-capitalize"></v-list-item-title>
                  <v-progress-linear indeterminate v-show="wallet.loading" height="8" color="accent" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </template>
        <template v-if="cardano.status === 'connected'">
          <v-card-text class="elevation-2 my-2 py-4 mb-4">
            <v-row justify="space-between" align="center">
              <div class="d-flex flex-row flex-nowrap align-center text-capitalize body-1">
                <v-img :src="cardano.ActiveWallet.icon" width="24" height="24" contain class="mr-2" />
                {{ cardano.ActiveWallet.name }} Connected
              </div>
              <v-btn color="accent" small class="my-2" @click="disconnect" :disabled="canCancel()">Disconnect</v-btn>
            </v-row>
          </v-card-text>
          <template v-if="buying">
            <template v-if="purchase.status === 'quantity'">
              <v-card-text class="py-4">
                <v-row align="center">
                  <v-col>
                    <v-img :src="imageSource(buying)" :alt="buying.name" contain width="256" />
                  </v-col>
                  <v-col cols="auto">
                    <div class="d-flex flex-column justify-center">
                      <v-btn color="accent" class="mb-2" v-for="qty in quantities" :key="qty"
                             :disabled="buying.remaining < qty" @click="buy(qty)">
                        Buy {{ qty }} | {{ quantityPrice(qty) }} &#8371;
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
            <template v-else-if="purchase.status === 'waiting'">
              <v-card-text class="text-center body-1 font-weight-bold">
                Awaiting Reservation
              </v-card-text>
              <v-card-text>
                We're attempting to reserve
                <v-chip small label color="secondary" class="mx-1">
                  {{ purchase.quantity }} {{ 'card' | pluralize(purchase.quantity) }}
                </v-chip>
                for you. Please be patient and keep this window open until it is your turn to purchase.
              </v-card-text>
              <v-card-text>
                <v-progress-linear indeterminate height="12" color="accent"></v-progress-linear>
              </v-card-text>
              <v-card-text v-if="purchase.queue.position" class="text-center">
                You are at position
                <v-chip small label color="secondary" class="mx-1">#{{ purchase.queue.position }}</v-chip>
                in line. There are
                <v-chip small label color="secondary" class="mx-1">{{ purchase.queue.ahead }}</v-chip>
                reserved ahead of you.
              </v-card-text>
            </template>
            <template v-else-if="purchase.status === 'pending'">
              <v-card-text class="text-center body-1 font-weight-bold">
                Ready to Mint
              </v-card-text>
              <v-card-text>
                We've reserved
                <v-chip small label color="secondary" class="ma-1">
                  {{ purchase.details.tokens.length }} {{ 'card' | pluralize(purchase.details.tokens.length) }}
                </v-chip>
                for you. The cost is
                <v-chip small label color="secondary" class="ma-1">
                  {{ toAda(purchase.details.payValue) }} &#8371;
                </v-chip>
                and you will receive
                <v-chip small label color="secondary" class="ma-1">
                  {{ purchase.details.tokens.length }} Buffy {{ 'Bit' | pluralize(purchase.details.tokens.length) }}
                </v-chip>
                free as our thank you gift! Your session will expire in
                <Countdown :timestamp="purchase.expires" @countdownComplete="cancelBuy"
                           class="font-weight-bold"></Countdown>
                When you're ready to complete your transaction click the button below.
              </v-card-text>
              <v-card-text class="text-center">
                <v-btn x-large color="accent" @click="mint" :disabled="preparingTxn" :loading="preparingTxn">Mint Now
                </v-btn>
              </v-card-text>
            </template>
            <template v-else-if="purchase.status === 'submitted'">
              <v-card-text class="text-center body-1 font-weight-bold">
                Transaction Submitted
              </v-card-text>
              <v-card-text>
                Your transaction has been successfully submitted to the blockchain. Please keep this window open while
                we await confirmation. Your assets will be revealed once the transaction is confirmed.
              </v-card-text>
              <v-card-text>
                Transaction ID: <span style="word-break: break-all" class="font-weight-bold">{{
                  purchase.transaction_id
                                                                                             }}</span>
              </v-card-text>
              <v-progress-linear height="12" indeterminate color="accent" class="mb-4"></v-progress-linear>
            </template>
            <template v-else-if="purchase.status === 'complete'">
              <v-card-text class="text-center body-1 font-weight-bold">
                Transaction Complete
              </v-card-text>
              <v-card-text>
                Your transaction is now complete. Thank you, again, for your support!
              </v-card-text>
              <v-carousel v-if="purchase.assets" class="my-4">
                <v-carousel-item v-for="asset in purchase.assets" :key="asset.name">
                  <v-sheet tile height="100%">
                    <v-row justify="center">
                      <v-card width="256" class="my-4">
                        <v-img :src="asset.image" contain :alt="asset.name"></v-img>
                        <v-card-title>{{ asset.name }}</v-card-title>
                      </v-card>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </template>
            <v-card-text class="text-center body-1 font-weight-bold">
              {{ buying.remaining }} Remaining!
            </v-card-text>
          </template>
          <v-card-text>
            Thank you for purchasing a promo card. Each card has a "common" and "rare" variation available that are
            randomly distributed. Your purchase of an NFT Update promo card goes to support our shows and content. Thank
            you so much for your support!
          </v-card-text>
        </template>
        <v-card-actions>
          <v-btn color="primary" @click="cancelBuy" :disabled="canCancel()" v-if="purchase.status !== 'complete'">
            CANCEL
          </v-btn>
          <v-btn color="primary" @click="reset" v-if="purchase.status === 'complete'">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import HeroBanner from "@/components/HeroBanner.vue";
import ShowHeader from "@/components/ShowHeader.vue";
import axios from "axios";
import SeasonList from "@/components/SeasonList.vue";
import Countdown from "@/components/Countdown.vue";

export default {
  name: 'App',

  components: {
    HeroBanner,
    ShowHeader,
    SeasonList,
    Countdown
  },

  data: () => ({
    connectingWallet: false,
    buying: null,
    seasons: [],
    quantities: [1, 3, 5],
    latestEpisode: null,
    showBuy: false,
    purchase: {
      status: 'quantity',
      quantity: 0,
      session_id: null,
      transaction_id: null,
      expires: null,
      queue: {
        position: 0,
        ahead: 0
      }
    },
    statusPoll: null,
    remainingPoll: null,
    checkingStatus: false,
    preparingTxn: false,
    BuffyBitsPolicy: "201bcd6107da0f2ecd70e65b0eb042349873281c569de38178630388",
    BuffyBitsHash: "8200581c141c4680658feba494efc98fab177af9aec4e01b8758d01536361cb4"
  }),
  methods: {
    reset() {
      this.buying = null;
      this.connectingWallet = false;
      this.purchase = {
        status: 'quantity',
        session_id: null,
        expires: null,
        quantity: 0,
        queue: {
          position: 0,
          ahead: 0
        }
      };
      clearInterval(this.statusPoll);
      this.statusPoll = null;
      clearInterval(this.remainingPoll);
      this.remainingPoll = null;
      this.showBuy = false;
    },
    async mint() {
      this.preparingTxn = true;
      const paymentAddress = await this.getChangeAddress();

      if (!paymentAddress) {
        console.error("Could not find a change address?");
        this.doError(`Could not locate your wallet's change address... That's weird.`, 'Error');
        return;
      }

      const mintAssets = [];
      const tokenMetadata = this.purchase.details.meta;
      const policy_hash = this.purchase.details.policy.policyHash;
      const policy_id = this.purchase.details.policy.polidyId;

      this.purchase.details.tokens.forEach((token) => {
        mintAssets.push({
          assetName: token,
          quantity: 1,
          policyId: policy_id,
          policyScript: policy_hash
        });
      });

      mintAssets.push({
        assetName: 'BuffyBit',
        quantity: this.purchase.details.tokens.length,
        policyId: this.BuffyBitsPolicy,
        policyScript: this.BuffyBitsHash
      });

      let mint_payment = {
        address: this.purchase.details.payTo,
        lovelace: this.purchase.details.payValue,
        assets: [],
        mintedAssets: []
      };

      const recipients = [
        mint_payment,
        {
          address: paymentAddress.to_bech32(),
          lovelace: 0,
          assets: [],
          mintedAssets: mintAssets
        }
      ];

      let tx;
      try {
        tx = await this.makeTransaction(recipients, tokenMetadata, this.purchase.details.ttl, this.purchase.details.metaDetails.hash);
      } catch (e) {
        console.error('Tx Build Error:', e);
        this.doError(`Sorry, we could not build the transaction at this time! :(`, 'Transaction Failed');
        this.preparingTxn = false;
        return;
      }

      let signed;
      try {
        signed = await this.signTransaction(tx);
      } catch (e) {
        console.error('Tx Sign Error:', e);
        this.preparingTxn = false;
        return;
      }

      let response;
      try {
        signed.session_id = this.purchase.session_id;
        response = await axios.post(`https://www.nftupdate.io/api/v2/submit/`, signed);
      } catch (e) {
        console.error('Tx Submit Error:', e);
        this.doError(`Sorry, we encountered an error attempting to submit your transaction!`, 'Submit Failed');
        this.preparingTxn = false;
        return;
      }
      if (response.data.transaction_id) {
        this.purchase.status = 'submitted';
        this.purchase.transaction_id = response.data.transaction_id;
      }
      this.preparingTxn = false;
    },
    async buy(quantity) {
      this.purchase.status = 'waiting';
      this.purchase.quantity = quantity;
      let token;
      try {
        token = await window.grecaptcha.execute('6LfOjB8bAAAAAB6ZHRG27FeZBmjwvrv_pbNX-8qZ', {
          action: 'BuyPromo'
        });
      } catch (e) {
        console.error("Recaptcha Error:", e);
        this.doError(`Sorry, we could not solve a recaptcha for your session at this time. Please try again later.`, 'Recaptcha Error');
        this.purchase.status = 'quantity';
        this.purchase.quantity = 0;
        return;
      }

      const args = {
        episode_id: this.buying.id,
        token: token,
        quantity: quantity
      };

      let response;
      try {
        response = await axios.post('https://www.nftupdate.io/api/v2/episode/reserve/', args);
      } catch (e) {
        console.error("Create Reservation Error:", e);
        this.doError(`Sorry, we could not create a reservation for you at this time!`, 'Reservation Error');
        this.purchase.status = 'quantity';
        this.purchase.quantity = 0;
        return;
      }

      this.purchase.session_id = response.data.session_id;
      this.statusPoll = setInterval(this.checkSession, 20000);
      await this.checkSession();
    },
    canCancel() {
      return !(['quantity', 'waiting', 'pending', 'cancel'].includes(this.purchase.status) && this.preparingTxn === false);
    },
    async cancelBuy() {
      this.showBuy = false;
      await axios.post('https://www.nftupdate.io/api/v2/cancel/', {
        session_id: this.purchase.session_id
      });
      this.buying = null;
      this.connectingWallet = false;
      this.purchase = {
        status: 'quantity',
        session_id: null,
        expires: null,
        quantity: 0,
        queue: {
          position: 0,
          ahead: 0
        }
      };
      clearInterval(this.statusPoll);
      this.statusPoll = null;
      clearInterval(this.remainingPoll);
      this.remainingPoll = null;
    },
    async checkSession() {
      if (this.checkingStatus) {
        return;
      }

      this.checkingStatus = true;
      let response;
      try {
        response = await axios.post('https://www.nftupdate.io/api/v2/poll/', {
          session_id: this.purchase.session_id
        });
      } catch (e) {
        console.error("Status Polling Error:", e);
        this.checkingStatus = false;
        return;
      }

      const session_details = response.data;


      if (session_details.status === 'waiting') {
        this.purchase.queue.position = session_details.qpos;
        this.purchase.queue.ahead = session_details.queue ?? 0;
      }

      if (session_details.status === 'pending') {
        if (this.purchase.expires === null) {
          this.purchase.expires = new Date(session_details.details.expirationTime);
        }
        this.purchase.details = session_details.details;
      }

      if (session_details.status === 'submitted') {
        this.purchase.transaction_id = session_details.txHash;
      }

      if (session_details.status === 'expired') {
        await this.cancelBuy();
      }

      if (session_details.status === 'complete') {
        this.purchase.assets = session_details.assets;
      }

      this.purchase.status = session_details.status;


      this.checkingStatus = false;
    },
    async connectTo(wallet) {
      this.connectingWallet = true;
      wallet.loading = true;
      try {
        await this.connect(wallet);
      } catch (e) {
        console.error("Wallet Connect Error:", e);
        this.doError(`Sorry, we could not connect to your chosen wallet. Please try again later.`, 'Connect Wallet Error');
      }
      this.connectingWallet = false;
      wallet.loading = false;
    },
    disconnect() {
      this.changeWallet();
    },
    async checkStatus(episode) {
      if (episode === undefined) {
        episode = this.buying;
      }

      let response;
      try {
        response = await axios.post('https://www.nftupdate.io/api/v2/episode/status/', {
          episode_id: episode.id
        });
      } catch (e) {
        console.error('Episode Status Error:', e);
        return;
      }

      if (!response.data.remaining) {
        episode.remaining = 0;
      } else {
        episode.remaining = parseInt(response.data.remaining);
      }
    },
    async doBuy(episode) {

      await this.checkStatus(episode);

      if (episode.remaining === 0) {
        this.doError(`Sorry, this episode has sold out!`, 'Sold Out');
        return;
      }

      if (this.cardano.status === 'notfound') {
        this.doError(
            `Sorry, we could not detect any Cardano light wallets installed in your browser. ` +
            `You must use a browser with a light wallet installed in order to purchase.`, 'Not Found');
        return;
      }

      this.remainingPoll = setInterval(this.checkStatus, 30000);

      this.showBuy = true;
      this.buying = episode;
    },
    doError(msg, title) {
      this.$swal.fire({
        type: 'error',
        icon: 'error',
        title: title,
        html: msg,
        showCloseButton: true
      });
    },
    imageSource(episode) {
      if (episode.image_id) {
        return 'https://imagedelivery.net/3RQCBy11_IKZdJLtH0_2mQ/' + episode.image_id + '/thumb';
      } else if (episode.ipfs) {
        return 'https://buffy.infura-ipfs.io/ipfs/' + episode.ipfs;
      } else {
        return 'https://via.placeholder.com/384x538?text=NFT+Update'
      }
    },
    async loadSeasons() {
      let response;
      try {
        response = await axios.get('https://www.nftupdate.io/api/v2/seasons/');
      } catch (e) {
        console.error('Load Seasons Error:', e);
      }
      this.seasons = response.data;
    },
    updateLatest(e) {
      this.latestEpisode = e;
    },
    async loadLatest() {

      const t_now = new Date();

      this.seasons.every(season => {
        if (season.episodes.length === 0) {
          return true;
        }
        season.episodes.every(episode => {
          const episode_start = new Date(episode.start);
          if (t_now > episode_start) {
            this.latestEpisode = episode;
            return false;
          }
          return true;
        });
        if (this.latestEpisode !== null) {
          return false;
        }
      });
    },
    quantityPrice(quantity) {
      const ada_value = this.toAda(this.buying.price);
      return (quantity * ada_value) - (quantity - 1);
    }
  },
  async mounted() {
    await this.loadSeasons();
    await this.loadLatest();
    this.checkForCardano();
  },
};
</script>

<style>
#title {
  font-family: Montserrat, sans-serif;
  font-weight: 800;
}

.swal2-container {
  font-family: Roboto, sans-serif;
}
</style>
