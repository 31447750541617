import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#ff5b66',
                secondary: '#c62368',
                accent: '#55748F'
            }
        }
    }
});
