<template>
  <v-card>
    <v-img :src="imgsrc" :alt="episode.name" contain />
    <v-card-title class="justify-center">
      <v-chip label class="me-4">#{{ episode.serial }}</v-chip>
      {{ episode.name }}
    </v-card-title>
    <v-card-text class="text-center pb-0" v-if="episode.video_url">
      <v-btn :href="episode.video_url" target="_blank" color="primary" class="mb-2 mr-2" x-large>
        <span class="mr-2">Watch Episode</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>

    </v-card-text>
    <template v-if="status() === 'pending'">
      <v-card-text class="pb-0">
        <v-alert color="light-blue" class="text-center mb-0" shaped text prominent>
          COMING SOON
        </v-alert>
      </v-card-text>
      <v-card-text class="text-center">
        Episode begins in
        <Countdown :timestamp="start" @countdownComplete="refresh()"></Countdown>
      </v-card-text>
    </template>
    <template v-else-if="status() === 'soldout'">
      <v-card-text>
        <v-alert color="red" dark class="text-center mb-0" shaped text prominent>
          SOLD OUT
        </v-alert>
      </v-card-text>
    </template>
    <template v-else-if="status() === 'over'">
      <v-card-text>
        <v-alert color="blue-grey" dark class="text-center mb-0" shaped text prominent>
          SALES OVER
        </v-alert>
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text>
        <v-alert color="green" class="text-center mb-0" shaped text prominent>
          AVAILABLE NOW
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn x-large block color="accent" class="" @click="buy">BUY NOW</v-btn>
      </v-card-actions>
    </template>
    <template v-if="['over','soldout'].includes(status())">
      <v-card-actions class="justify-center">
        <v-btn large
               :href="`https://www.jpg.store/collection/${episode.policy_id}?searchText=${episode.name.replace(' ','+')}`"
               target="_blank" color="yellow" class="mb-2 mr-2">
          <v-img :src="require('../assets/jpg-icon.png')" contain width="38" height="38" />
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import Countdown from "@/components/Countdown.vue";

export default {
  name: "EpisodeCard",
  props: ['episode'],
  emits: ['buyCard', 'newRelease'],
  components: {
    Countdown
  },
  methods: {
    buy() {
      this.$emit('buyCard', this.episode);
    },
    refresh() {
      this.$emit('newRelease', this.episode);
      this.$forceUpdate();
    },
    status() {
      const date = new Date();
      const episode_start = new Date(this.episode.start);
      const episode_end = new Date(this.episode.end);
      if (parseInt(this.episode.remaining) === 0) {
        return 'soldout';
      }

      if (date > episode_end) {
        return 'over';
      }

      if (date < episode_start) {
        return 'pending';
      }

      return true;
    }
  },
  computed: {
    imgsrc: {
      get() {
        if (this.episode.image_id) {
          return 'https://imagedelivery.net/3RQCBy11_IKZdJLtH0_2mQ/' + this.episode.image_id + '/thumb';
        } else if (this.episode.ipfs) {
          return 'https://buffy.infura-ipfs.io/ipfs/' + this.episode.ipfs;
        } else {
          return 'https://via.placeholder.com/384x538?text=NFT+Update'
        }
      }
    },
    start() {
      return new Date(this.episode.start);
    }
  }
}
</script>

<style scoped>

</style>