<template>
  <div id="collection">
    <v-container class="py-4">
      <v-tabs center-active grow v-model="activeSeason">
        <v-tab v-for="season in seasons" :key="season.id">{{ season.name }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeSeason">
        <v-tab-item v-for="season in seasons" :key="season.id">
          <v-row class="py-8" justify="center" v-if="season.episodes.length">
            <v-col cols="12" md="6" lg="3" xl="3" v-for="episode in season.episodes" :key="episode.id" >
              <EpisodeCard :episode="episode" @buyCard="handleBuy" @newRelease="doNewRelease"></EpisodeCard>
            </v-col>
          </v-row>
          <v-row v-else class="py-8" justify="center">
            <v-col class="text-center">
              <h2 class="display-3 py-16">Coming Soon&trade;</h2>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>

  </div>
</template>

<script>
import EpisodeCard from "@/components/EpisodeCard.vue";

export default {
  name: "SeasonList",
  props: ['seasons'],
  emits: ['triggerBuy', 'newRelease'],
  components: {
    EpisodeCard
  },
  data: () => ({
    activeSeason: null
  }),
  methods: {
    handleBuy(e) {
      this.$emit('triggerBuy', e);
    },
    doNewRelease(e) {
      this.$emit('newRelease', e);
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>