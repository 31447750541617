<template>
  <div id="hero" class="py-16">
    <v-container>
      <v-row justify="center" align="center" v-if="latest !== null">
        <v-col class="px-10">
          <v-img height="538" width="384" class="ms-md-auto" contain :src="imgsrc" />
        </v-col>
        <v-col class="px-10">
          <h2>{{ latest.name }}</h2>
          <template v-if="status() === 'soldout'">
            <h3>Sold Out</h3>
            <v-btn color="accent" x-large href="#collection">Explore More</v-btn>
          </template>
          <template v-else-if="status() === 'over'">
            <h3>Sales Over</h3>
            <v-btn color="accent" x-large href="#collection">Explore More</v-btn>
          </template>
          <template v-else>
            <h3>Available Now</h3>
            <v-btn color="accent" x-large @click="buy">Buy Now</v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HeroBanner",
  props: ['latest'],
  emits: ['triggerBuy'],
  methods: {
    buy() {
      this.$emit('triggerBuy', this.latest);
    },
    status() {
      const date = new Date();
      const episode_start = new Date(this.latest.start);
      const episode_end = new Date(this.latest.end);
      if (parseInt(this.latest.remaining) === 0) {
        return 'soldout';
      }

      if (date > episode_end) {
        return 'over';
      }

      if (date < episode_start) {
        return 'pending';
      }

      return true;
    }
  },
  computed: {
    imgsrc: {
      get() {
        if (this.latest.image_id) {
          return 'https://imagedelivery.net/3RQCBy11_IKZdJLtH0_2mQ/' + this.latest.image_id + '/thumb';
        } else if (this.latest.ipfs) {
          return 'https://buffy.infura-ipfs.io/ipfs/' + this.latest.ipfs;
        } else {
          return 'https://via.placeholder.com/384x538?text=NFT+Update'
        }
      }
    }
  }
}
</script>

<style scoped>
#hero {
  background: url('../assets/header-bg.svg') no-repeat bottom center;
  background-size: cover;
  color: white;
  z-index: 99;
  position: inherit;
}

#hero h2 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 40px;
  text-transform: uppercase;
  color: white;
}

#hero h3 {
  font-family: Montserrat, sans-serif;
  font-weight: 900;
  font-size: 52px;
  text-transform: uppercase;
}
</style>